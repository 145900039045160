import { useRouter } from "next/router"

import { useAppContext } from "../core/useAppContext"
import { useAuthContext } from "../core/useAuthContext"

interface AppRedirectOptions {
  enabled?: boolean
  addAppIdPrefix?: boolean
}

/**
 * A hook to redirect to path on call
 ** @param {string} path - Path to redirect to
 * @param {AppRedirectOptions} redirectOptions - options to enable or prefix redirect
 **/
export function useAppRedirect(path: string, redirectOptions?: AppRedirectOptions) {
  const { enabled = true, addAppIdPrefix = true } = redirectOptions ?? {}
  const router = useRouter()
  const { session, isLoading } = useAuthContext()
  const { currentApp } = useAppContext()
  const appId: string = currentApp?.id

  // Prefix app routes with correct appId
  const routePrefix = addAppIdPrefix && appId ? `/app/org/${appId}` : ""

  // If router is not ready or auth is loading return
  if (!router.isReady || isLoading) {
    return
  }

  // If logged out
  if (router.pathname !== "login" && session == null) {
    const routeParam = { pathname: "/login", query: {} }
    if (router.asPath && !router.query?.redirectURL) {
      routeParam.query = { redirectURL: router.asPath }
    }
    router.push(routeParam)
    return
  }

  // Wait for appid to load
  if ((addAppIdPrefix && !appId) || !enabled) {
    return
  }

  router.replace(`${routePrefix}/${path}`)
}
